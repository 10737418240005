<template>
  <KDialog
    :title="title"
    :loading="loading"
    :visible="visible"
    :actions="dialogActions"
    @click:remove="remove"
    @click:close="close"
  >
    <!-- Conteúdo -->
    <v-card-text class="headline"
      >Deseja realmente apagar o item "{{ data.day }}"?</v-card-text
    >
  </KDialog>
</template>

<script>
import KDialog from "@/components/KDialog";
export default {
  components: {
    KDialog
  },

  data() {
    return {
      //
      data: {},
      // Dialog
      loading: false,
      title: null,
      visible: false,
      dialogActions: [
        { label: "Remover", eventName: "click:remove", color: "red" }
      ]
    };
  },

  methods: {
    async remove() {
      try {
        this.loading = true;

        const response = await this.$store.dispatch(
          "expiration_dates/remove",
          this.data
        );

        this.$message.success(response.message);
        this.loading = false;
        this.close();
      } catch (error) {
        // console.log(error);
        this.$message.serverError(error);
        this.loading = false;
        this.close();
      }
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    openToRemove({ id, day }) {
      // Mescla os dados recebidos para não alterar o que está para trás
      this.data = {
        id,
        day
      };

      this.title = `Deletar ${day}`;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.visible = false;
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.data = {};
    }
  }
};
</script>

<style>
</style>
